<template>
	<div :class="themeClass">
		<div class="AboutJuCai-img">
			<div class="AboutJuCai-White"></div>
			<!-- <img src="../assets/imgs/about.jpg" alt=""/> -->
			<p>聚材通平台</p>
		</div>
		<el-tabs type="border-card">
			<el-tab-pane label="平台简介">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="企业简介" name="first"><RichTextStyle :html="content" :styleRule="rule" /></el-tab-pane>
					<el-tab-pane label="企业文化" name="second"><RichTextStyle :html="content1" :styleRule="rule" /></el-tab-pane>
				</el-tabs>
			</el-tab-pane>
			<el-tab-pane label="特别申明">特别申明</el-tab-pane>
			<el-tab-pane label="使用帮助">使用帮助</el-tab-pane>
			<el-tab-pane label="给我评分">定时任务补偿</el-tab-pane>
			<el-tab-pane label="隐私政策">隐私政策</el-tab-pane>
			<el-tab-pane label="给我评分">定时任务补偿</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import RichTextStyle from '@/components/richtext/RichTextStyle';
export default {
	components: {
		RichTextStyle
	},
	data() {
		return {
			activeName: 'second',
			content: `<div class="content_area" style=" font-size:13px;line-height:28px;color:#999999;">
						                    
						    <!--repaste.body.begin--><p>　　  新疆聚材通电子商务有限公司（以下简称为聚材通公司）成立于2016年8月19日，注册资本伍佰万元人民币，是新疆天山水泥股份有限公司（以下简称天山股份）的全资子公司，主要经营聚材通平台平台网站（www.xjjcjc.com和www.xjjcjc.cn和 www.xjjcjc.com.cn ）。</p>
						<p>　　聚材通公司（商城）也是天山股份作为行业龙头企业，率先尝试“互联网 水泥”的电子商务模式，进行自身管理、运营模式的全面创新，力图以电子商务助力传统销售升级，给企业带来新的活力并健康成长的产物。</p>
						<p>　　“聚材通”为聚集建材之意。目前聚材通公司（商城）主要进行线上水泥销售，已开通天山股份旗下疆内所有二十一家制造厂的线上销售业务。后期聚材通公司（商城）将会给广大客户提供更多的建材类产品。</p>
						<p>　　新疆聚材通电子商务有限公司（以下简称为聚材通公司）成立于2016年8月19日，注册资本伍佰万元人民币，是新疆天山水泥股份有限公司（以下简称天山股份）的全资子公司，主要经营聚材通平台平台网站（www.xjjcjc.com和www.xjjcjc.cn和 www.xjjcjc.com.cn ）。</p>
						<p>　　聚材通公司（商城）也是天山股份作为行业龙头企业，率先尝试“互联网 水泥”的电子商务模式，进行自身管理、运营模式的全面创新，力图以电子商务助力传统销售升级，给企业带来新的活力并健康成长的产物。</p>
						<p>　　“聚材通”为聚集建材之意。目前聚材通公司（商城）主要进行线上水泥销售，已开通天山股份旗下疆内所有二十一家制造厂的线上销售业务。后期聚材通公司（商城）将会给广大客户提供更多的建材类产品。</p>
						<p>　　新疆聚材通电子商务有限公司（以下简称为聚材通公司）成立于2016年8月19日，注册资本伍佰万元人民币，是新疆天山水泥股份有限公司（以下简称天山股份）的全资子公司，主要经营聚材通平台平台网站（www.xjjcjc.com和www.xjjcjc.cn和 www.xjjcjc.com.cn ）。</p>
						<p>　　聚材通公司（商城）也是天山股份作为行业龙头企业，率先尝试“互联网 水泥”的电子商务模式，进行自身管理、运营模式的全面创新，力图以电子商务助力传统销售升级，给企业带来新的活力并健康成长的产物。</p>
						<p>　　“聚材通”为聚集建材之意。目前聚材通公司（商城）主要进行线上水泥销售，已开通天山股份旗下疆内所有二十一家制造厂的线上销售业务。后期聚材通公司（商城）将会给广大客户提供更多的建材类产品。</p>
						<p>　　新疆聚材通电子商务有限公司（以下简称为聚材通公司）成立于2016年8月19日，注册资本伍佰万元人民币，是新疆天山水泥股份有限公司（以下简称天山股份）的全资子公司，主要经营聚材通平台平台网站（www.xjjcjc.com和www.xjjcjc.cn和 www.xjjcjc.com.cn ）。</p>
						<p>　　聚材通公司（商城）也是天山股份作为行业龙头企业，率先尝试“互联网 水泥”的电子商务模式，进行自身管理、运营模式的全面创新，力图以电子商务助力传统销售升级，给企业带来新的活力并健康成长的产物。</p><!--repaste.body.end-->
			</div>`,
			content1: `<div class="content_area" style=" display:block;line-height:28px;">
						                    
						                        <!--repaste.body.begin--><p style="fone-size:13px;color: #0173FE;font-weight:700;">　　  我们的使命是让买水泥更简单</p>
						<p style="color:#999999;font-size:13px;">　　我们为客户提供基础的平台服务，改变以往水泥产品购买流程，带来更加便捷的购物体验，连接客户、物流、用户，使水泥买卖更高效。</p>
						<p style="fone-size:13px;color: #0173FE;font-weight:700;">　　我们的愿景是做中国最好的水泥电商平台</p>
						<p style="color:#999999;font-size:13px;">　　未来的聚材通平台会聚集众多水泥品牌、厂家及其他建材商户，相信也会有越来越多的客户和合作伙伴，我们也会通过物流、金融等服务逐步完善商城的产业生态。</p>
						<p style="fone-size:13px;color: #0173FE;font-weight:700;">　　我们的价值观。</p>
						<p style="font-size:13px;color:#000000;>　　成就--成就客户，成就自己</p>
						<p style="font-size:13px;color:#000000;>　　变化--拥抱变化，乐于创新</p>
						<p style="font-size:13px;color:#000000;>　　敬业--专业执着，精益求精</p>
						<p style="font-size:13px;color:#000000;>　　激情--充满激情地做一件有意义的事。</p>
						<p style="fone-size:13px;color: #0173FE;font-weight:700;text-indent:0.8em;">　    我们的使命是让买水泥更简单</p>
						<p style="color:#999999;font-size:13px;">　　我们为客户提供基础的平台服务，改变以往水泥产品购买流程，带来更加便捷的购物体验，连接客户、物流、用户，使水泥买卖更高效。</p>
						<p style="fone-size:13px;color: #0173FE;font-weight:700;">　　我们的愿景是做中国最好的水泥电商平台</p>
						<p style="color:#999999;font-size:13px;">　　未来的聚材通平台会聚集众多水泥品牌、厂家及其他建材商户，相信也会有越来越多的客户和合作伙伴，我们也会通过物流、金融等服务逐步完善商城的产业生态。</p>
						<p style="fone-size:13px;color: #0173FE;font-weight:700;">　　我们的价值观。</p>
						<p style="font-size:13px;color:#000000;>　　成就--成就客户，成就自己</p>
						<p style="font-size:13px;color:#000000;>　　变化--拥抱变化，乐于创新</p>
						<p style="font-size:13px;color:#000000;>　　敬业--专业执着，精益求精</p>
						<p style="font-size:13px;color:#000000;>　　激情--充满激情地做一件有意义的事。</p>
						<p style="fone-size:13px;color: #0173FE;font-weight:700;text-indent:0.8em;">　  我们的使命是让买水泥更简单</p>
						<p style="color:#999999;font-size:13px;">　　我们为客户提供基础的平台服务，改变以往水泥产品购买流程，带来更加便捷的购物体验，连接客户、物流、用户，使水泥买卖更高效。</p><!--repaste.body.end-->
			</div>`,
			rule: [] //富文本规则
		};
	},
	computed: {
		...mapGetters(['getThemeName']),
		themeClass() {
			return `theme-${this.getThemeName}`;
		}
	},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		}
	}
};
</script>

<style lang="scss" scoped>
.AboutJuCai-img {
	position: relative;
	background: url(../assets/imgs/about.jpg) no-repeat;
	background-size: 920px 150px;
	margin-top: 10px;
}
::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
	@include themify($themes) {
		color: themed('themes_color') !important;
	}
}
::v-deep .el-tabs__item.is-active {
	@include themify($themes) {
		color: themed('themes_color') !important;
	}
}
::v-deep .el-tabs__active-bar {
	@include themify($themes) {
		background: themed('themes_color') !important;
	}
}
::v-deep .el-tabs__nav .el-tabs__item:hover {
	@include themify($themes) {
		color: themed('themes_color') !important;
	}
}
.AboutJuCai-White {
	width: 920px;
	height: 150px;
	background-color: #ffffff;
	opacity: 0.5;
}

.AboutJuCai-img p {
	color: #FFF;
	font-size: 45px;
	font-weight: bolder;
	position: absolute;
	left: 350px;
	top: 50px;
	// z-index:1;
}

::v-deep .el-tabs__nav-wrap::after {
	display: none;
}
</style>
